{
	"2FA Code": "Two-factor Authentication Code",
	"2FA Code is required": "Two-factor Authentication Code is required",
	"ACCEPTED": "Accepted",
	"APPROVED": "Approved",
	"ARTICLE GENERATION": "Article Generation",
	"ARTICLE": {
		"COPYRIGHT": "Copyright",
		"SOURCE": "Source"
	},
	"Add": "Add",
	"Add to article": "Add to Article",
	"Advertiser": "Advertiser",
	"Application error occurred": "Application Error Occurred",
	"Approval time": "Approval time",
	"Article ID": "Article ID",
	"Article assets": "Article Assets",
	"Article summary in English": "Article summary in English",
	"Author": "Author",
	"Budget amount": "Budget Amount",
	"Budget type": "Budget Type",
	"CAMPAIGN": "Campaign",
	"CANCELLED": "Cancelled",
	"CMS": "CMS",
	"CONTENT": "Content",
	"CTA": "Call to Action",
	"CTR": "CTR",
	"Campaign name": "Campaign Name",
	"Cancel": "Cancel",
	"Categories": "Categories",
	"Category": "Category",
	"Change password": "Change Password",
	"Clear": "Clear",
	"Clicks": "Clicks",
	"Code must contains 6 characters": "Code must contain 6 characters",
	"Collapse": "Collapse",
	"Countries": "Countries",
	"Country": "Country",
	"Create": "Create",
	"Create article": "Create Article",
	"Create lockscreen resource": "Create Lockscreen Resource",
	"Created By": "Created By",
	"Created Time": "Created Time",
	"Created by": "Created by",
	"Created time": "Created time",
	"Creator": "Creator",
	"DRAFT": "Draft",
	"Date": "Date",
	"Description": "Description",
	"Download": "Download",
	"Duplicate Lockscreen Resources": "Duplicate Lockscreen Resources",
	"Duration (Article)": "Duration (Article)",
	"Duration (LS)": "Duration (LS)",
	"Duration days": "Duration (Days)",
	"EXPIRED": "Expired",
	"Email": "Email",
	"Email address": "Email Address",
	"Email address required": "Email Address Required",
	"Email is required": "Email is required",
	"Email with temporary password has been sent!": "Email with temporary password has been sent!",
	"End date": "End date",
	"Enter a CTA": "Enter a Call to Action",
	"Enter a Summary": "Enter a Summary",
	"Enter a author": "Enter an Author",
	"Enter a campaign name": "Enter a Campaign Name",
	"Enter a description": "Enter a Description",
	"Enter a duration days": "Enter a Duration (Days)",
	"Enter a landing page URL": "Enter a Landing Page URL",
	"Enter a subtitle": "Enter a subtitle",
	"Enter a title": "Enter a Title",
	"Enter a traffic percent": "Enter a traffic percent",
	"Enter a weekly frequency": "Enter a weekly frequency",
	"Enter an ID": "Enter an ID",
	"Enter your email address": "Enter your Email Address",
	"Existing images suggestions": "Existing images suggestions",
	"Expand": "Expand",
	"Export": "Export",
	"Field is required": "Field is required",
	"Field value should be 0 or more": "Field value should be 0 or more",
	"Field value should be integers": "Field value should be integers",
	"File size is too large": "File size is too large",
	"Forgot your password?": "Forgot your password?",
	"Frequency": "Frequency",
	"Fullscreen": "Fullscreen",
	"ID": "ID",
	"IN_REVIEW": "In Review",
	"If you have an account, we'll email you instructions": "If you have an account, we'll email you instructions",
	"Image provider": "Image Provider",
	"Impression": "Impression",
	"Impressions": "Impressions",
	"Incorrect email address": "Incorrect email address",
	"Invalid dimensions": {
		" Expected resolution is 1440 x 2560": "Invalid dimensions. Expected resolution is 1440 x 2560"
	},
	"LIVE": "Live",
	"Landing page URL": "Landing Page URL",
	"Language": "Language",
	"List empty": "List empty",
	"Lockscreen resources": "Lockscreen Resources",
	"Log in": "Log in",
	"Login": "Login",
	"Login with Google": "Login with Google",
	"Logout": "Logout",
	"Min CTR": "Min CTR",
	"Min impressions": "Min impressions",
	"NO STATUS": "No Status",
	"NO TYPE": "No Type",
	"NOT AVAILABLE": "Not available",
	"Name": "Name",
	"Name, ID": "Name, ID",
	"New password": "New Password",
	"OEM": "OEM",
	"PAGE": {
		"ARTICLE": {
			"ACTIONS": {
				"ACCEPT": {
					"LABEL": "Accept",
					"TOOLTIP": "Article cannot be accepted without a category"
				},
				"CROP": "Crop",
				"EDIT": "Edit",
				"PROMOTE": {
					"DISABLED": "This article is already marked as Trending News",
					"LABEL": "Promote",
					"TOOLTIP": "Click to mark this article as Trending News"
				},
				"PROMOTE_GLOBAL": {
					"LABEL": "Promote",
					"TOOLTIP": "Click to mark this article as Global Search News"
				},
				"REJECT": "Reject"
			},
			"FIELD": {
				"ID": {
					"TOOLTIP": "Article ID",
					"VALUE": "ID: {{value}}"
				}
			},
			"LIST": {
				"ACTION": {
					"CREATE": "Create"
				},
				"HEADER": "Articles",
				"SEARCH_TERM": {
					"LABEL": "Title, author, created by, articleURL"
				}
			},
			"SELECTED_ELEMENTS": {
				"ACTIONS": {
					"ACCEPT_ALL": "Accept all",
					"CLEAR_SELECTION": "Clear selection",
					"DUPLICATE_ALL": "Duplicate all",
					"PROMOTE_ALL": "Promote all",
					"REJECT_ALL": "Reject all",
					"SELECT_ALL": "Select all"
				},
				"LABEL": "Selected elements: {{value}}"
			},
			"STATUS": {
				"DONE": "Done",
				"FINISHING": "Finishing",
				"NO_STATUS": "No status",
				"PROCESSING": "Processing"
			}
		},
		"ARTICLE_SUGGESTIONS": {
			"DIALOG": {
				"HEADER": "Browse by suggestion set"
			},
			"LIST_ITEM": {
				"ACTION": {
					"SHOW_MORE": "See more"
				},
				"HEADER": "Suggestion {{value}}"
			},
			"SEARCH": {
				"HEADER": "Existing images suggestions"
			}
		},
		"AUDIENCE": {
			"CREATE": "Click the \"Add\" button to create a new audience."
		},
		"CAMPAIGN": {
			"ACTIONS": {
				"DETAILS": "Details"
			},
			"CREATE": {
				"ALERT": {
					"ERROR": "There was an error creating the campaign. Please try again.",
					"SUCCESS": "Campaign created successfully!"
				},
				"FORM": {
					"ACTIONS": {
						"CANCEL": "Cancel",
						"SUBMIT": "Submit"
					}
				},
				"HEADER": "Create campaign",
				"LABEL": "Add new campaign"
			},
			"DATEPICKER": {
				"LABEL": "Select date"
			},
			"DETAILS": {
				"FIELD": {
					"AUDIENCES": "Audiences",
					"CAMPAIGN_ID": "Campaign ID",
					"END_DATE": "End date",
					"START_DATE": "Start date",
					"TRAFFIC_PERCENT": "Traffic percent"
				},
				"HEADER": "Campaign details"
			},
			"HEADER": "Campaigns",
			"SEARCH_TERM": {
				"LABEL": "Search"
			}
		},
		"COMPONENTS": {
			"COPY_TO_CLIPBOARD": {
				"ALERT": "Copied to clipboard!",
				"LABEL": "Copy to clipboard"
			},
			"DASHBOARD": {
				"EMPTY": {
					"HEADER": "No data",
					"SUBHEADER": "No results for selected criteria"
				}
			},
			"DATEPICKER": {
				"LABEL": "Select date",
				"SIDE_PANEL": {
					"LABEL": "Filter dates by"
				}
			},
			"DIALOG": {
				"BUTTONS": {
					"BACK": "Back"
				}
			},
			"IMAGE_UPLOAD": {
				"IN_PROGRESS": "Uploading..."
			},
			"LIST": {
				"EMPTY": {
					"HEADER": "List empty",
					"SUBHEADER": "No results for selected criteria"
				}
			},
			"SELECT_FILE": {
				"HEADER": "Drag and drop a file to upload",
				"SUBHEADER": "Or select a file from your computer"
			}
		},
		"CONFIRMATION_DIALOG": {
			"CANCEL": "Cancel",
			"OK": "OK"
		},
		"DASHBOARD": {
			"DATEPICKER": {
				"LABEL": "Select date"
			},
			"FILTER": {
				"OPEN_TYPE": {
					"LABEL": "Open type"
				},
				"POSITION": {
					"LABEL": "Position"
				}
			},
			"OVERVIEW": {
				"HEADER": "Dashboard"
			}
		},
		"DETAILS": {
			"ERROR": {
				"HEADER": "An unexpected error occurred.",
				"SUBHEADER": "Try again later!"
			}
		},
		"ERROR": {
			"ACTION": "Back to dashboard",
			"HEADER": "The page you are looking for is not available"
		},
		"FIELD": {
			"ADVERTISER": "Advertiser",
			"CATEGORY": "Category",
			"CLUSTER": "Cluster",
			"HAS_LOCKSCREEN_RESOURCE": "Has lockscreen resources",
			"HAS_TRENDING_NEWS": "Has trending news",
			"HAS_VIDEO": "Has video",
			"ORIENTATION": "Orientation",
			"PUBLICATION": "Publisher",
			"TAGS": "Tags",
			"LOCKSCREEN_TYPE": "Lockscreen Type",
			"CONTENT_TYPE": "Content Type",
			"VIDEO_PROVIDER": "Video provider",
			"VISIBLE": "Visible"
		},
		"FILTER": {
			"DASHBOARD": {
				"OPEN_TYPE": {
					"OPTIONS": {
						"CTA": "CTA",
						"SLIDEUP": "Slide Up"
					}
				},
				"POSITION": {
					"OPTIONS": {
						"ACTIVE": "Active",
						"PASSIVE": "Passive"
					}
				}
			},
			"GENERATION_JOB": {
				"STATUS": {
					"LABEL": "Status"
				}
			},
			"HAS_LOCKSCREEN_RESOURCE": {
				"OPTIONS": {
					"NO": "Not on lockscreen",
					"YES": "On lockscreen"
				}
			},
			"HAS_TRENDING_NEWS": {
				"OPTIONS": {
					"NO": "No",
					"YES": "Yes"
				}
			},
			"HAS_VIDEO": {
				"OPTIONS": {
					"NO": "No",
					"YES": "Yes"
				}
			},
			"ORIENTATION": {
				"OPTIONS": {
					"HORIZONTAL": "Horizontal",
					"SQUARE": "Square",
					"VERTICAL": "Vertical"
				}
			},
			"VISIBLE": {
				"OPTIONS": {
					"NO": "No",
					"YES": "Yes"
				}
			},
			"WORD_COUNT": {
				"FROM": "From",
				"LABEL": "Word count",
				"SUBHEADER": "Word count",
				"TO": "Words count"
			}
		},
		"FILTERS": {
			"NONE": "None",
			"OEM": {
				"LABEL": "OEM"
			},
			"OPTIONS": {
				"ALL": "All"
			}
		},
		"FORM": {
			"ERRORS": {
				"PATTERN": "Incorrect format",
				"REQUIRED": "Field is required"
			}
		},
		"GENERATION_JOB": {
			"ACTIONS": {
				"CANCEL": "Cancel",
				"DETAILS": "Details",
				"VIEW_ARTICLES": "View articles"
			},
			"CREATE": {
				"ALERT": {
					"ERROR": "File upload failed. Details: {{value}}",
					"SUCCESS": "Generation job created"
				},
				"FORM": {
					"ACTIONS": {
						"CANCEL": "Cancel",
						"SUBMIT": "Submit",
						"VERIFY": "Verify"
					},
					"FIELDS": {
						"FILE": {
							"LABEL": "Upload your file"
						},
						"PROMPT_TEMPLATE": {
							"LABEL": "Enter a prompt template",
							"PLACEHOLDER": "Prompt template"
						}
					}
				},
				"HEADER": "Create generation job",
				"LABEL": "Create",
				"PROMPTS": "Prompts"
			},
			"DATEPICKER": {
				"LABEL": "Enter a created date"
			},
			"DETAILS": {
				"ACTIONS": {
					"CANCEL": "Cancel"
				},
				"FIELD": {
					"CREATED_BY": "Created by",
					"CREATED_TIME": "Created time",
					"JOB_ID": "Job ID",
					"JOB_STATUS": "Status",
					"JOB_TYPE": "Type",
					"PROMPT_TEMPLATE": "Prompt template",
					"STATUS": "Status",
					"TASKS_FINISHED": "Tasks finished",
					"UPDATED_TIME": "Updated time"
				},
				"HEADER": "Generation job details",
				"WARNING": {
					"TASKS_NOT_FINISHED": "Some tasks are not finished"
				}
			},
			"HEADER": "Generation jobs",
			"SEARCH_TERM": {
				"LABEL": "Created by"
			},
			"STATUS": {
				"CANCELLED": "Cancelled",
				"CREATED": "Created",
				"DONE": "Done",
				"FAILED": "Failed",
				"INITIAL": "Initial",
				"IN_PROGRESS": "In progress"
			},
			"UPLOAD": {
				"HINT": "Allowed file extension: `.tsv`"
			},
			"VERIFY": {
				"ALERT": {
					"ERROR": "Verification failed. Details: {{value}}",
					"SUCCESS": "Verification succeeded"
				}
			}
		},
		"GLOBAL_SEARCH_NEWS": {
			"ACTIONS": {
				"DETAILS": "Details",
				"EXPIRE": "Expire",
				"PUBLISH": "Publish",
				"REMOVE": "Remove",
				"UNPUBLISH": "Unpublish"
			},
			"DATEPICKER": {
				"LABEL": "Select date"
			},
			"DETAILS": {
				"FIELD": {
					"ID": "ID",
					"STATUS": "Status"
				},
				"HEADER": "Global search news details"
			},
			"HEADER": "Global search news",
			"SEARCH_TERM": {
				"LABEL": "Search term"
			},
			"STATUS": {
				"ALL": "All",
				"APPROVED": "Approved",
				"DRAFT": "Draft",
				"EXPIRED": "Expired",
				"LIVE": "Live"
			},
			"TYPE": {
				"BREAKING": "Breaking",
				"ROUTINE": "Routine"
			},
			"UPLOAD": {
				"HINT": "Allowed file extension: `.zip`"
			}
		},
		"LIST": {
			"ERROR": {
				"HEADER": "An unexpected error occurred.",
				"SUBHEADER": "Try again later!"
			},
			"TOTAL": "Total: {{value}}"
		},
		"LOCKSCREEN_IMPORT": {
			"ACTIONS": {
				"DETAILS": "Details"
			},
			"CREATE": {
				"ALERT": {
					"ERROR": "Error occurred while creating.",
					"SUCCESS": "Articles imported successfully."
				},
				"FORM": {
					"ACTIONS": {
						"CANCEL": "Cancel",
						"RETRY": "Retry",
						"SUBMIT": "Submit"
					},
					"FIELDS": {
						"FILE": {
							"LABEL": "Upload archive file"
						},
						"GOOGLE_SHEET_URL": {
							"LABEL": "Enter a GSheet URL",
							"PLACEHOLDER": "GSheet URL"
						}
					}
				},
				"HEADER": "Import articles",
				"LABEL": "Import"
			},
			"DATEPICKER": {
				"LABEL": "Select date"
			},
			"DETAILS": {
				"FIELD": {
					"CREATED_BY": "Created by",
					"CREATED_TIME": "Created time",
					"CURRENT_ARCHIVE_URL": "Current archive URL",
					"GOOGLE_SHEET_URL": "GSheet URL",
					"IMPORT_ID": "Import ID",
					"STATUS": "Status",
					"UPDATED_TIME": "Updated time",
					"VALIDATION_ERRORS": "Validation errors",
					"VALIDATION_ERRORS_TOOLTIP": "Validation errors"
				},
				"HEADER": "Import details"
			},
			"HEADER": "Lockscreen imports",
			"SEARCH_TERM": {
				"LABEL": "Search term"
			},
			"STATUS": {
				"ALL": "All",
				"DONE": "Done",
				"ERROR": "Error",
				"FINISHING": "Finishing",
				"NEW": "New",
				"PROCESSING": "Processing",
				"TO_REFRESH": "To refresh"
			},
			"UPLOAD": {
				"HINT": "Allowed file extension: `.zip`"
			}
		},
		"LOCKSCREEN_RESOURCE": {
			"ACTIONS": {
				"DUPLICATE": "Duplicate",
				"EDIT": "Edit",
				"PUBLISH": "Publish",
				"REMOVE": "Remove",
				"WITHDRAW": "Withdraw"
			},
			"IMAGE_RECOMMENDATIONS": {
				"DISCOVER": "Discover images from AI recommendations."
			},
			"SELECTED_ELEMENTS": {
				"ACTIONS": {
					"CLEAR_SELECTION": "Clear selection",
					"PUBLISH_ALL": "Publish all",
					"SELECT_ALL": "Select all"
				},
				"LABEL": "Selected elements: {{value}}"
			}
		},
    "PUBLISHERS": {
      "ACTIONS": {
        "DETAILS": "Details"
      },
      "DETAILS":{
        "FIELD":{
          "ID": "ID"
        },
        "HEADER": "Publisher details"
      },
      "HEADER": "Publishers",
      "SEARCH_TERM": {
        "LABEL": "Search term"
      },
        "UPLOAD": {
          "HINT": "Allowed file extension: `.svg` , `,img`, `.png"
        }
    },
		"SIDENAV": {
			"ARTICLES": "Articles",
			"ARTICLES_LEGACY": "Articles (Legacy)",
			"CAMPAIGNS": "Campaigns",
			"DASHBOARD": "Dashboard",
			"GENERATION_JOBS": "Generation jobs",
			"GLOBAL_SEARCH_NEWS": "Global search",
			"LOCKSCREEN_IMPORTS": "Lockscreen imports",
			"LOCKSCREEN_RESOURCES": "Lockscreen resources",
			"PUBLISHERS": "Publishers",
			"TRENDING_NEWS": "Trending news",
			"VIDEOS": "Videos",
			"VIDEO_CAMPAIGNS": "Video Campaigns"
		},
		"TRENDING_NEWS": {
			"ACTIONS": {
				"DETAILS": "Details",
				"PUBLISH": "Publish",
				"REMOVE": "Remove",
				"UNPUBLISH": "Unpublish"
			},
			"DATEPICKER": {
				"LABEL": "Select date"
			},
			"DETAILS": {
				"FIELD": {
					"ID": "ID",
					"STATUS": "Status",
					"TYPE": "Type"
				},
				"HEADER": "Trending News details",
				"PREVIEW_LABEL": "Preview"
			},
			"HEADER": "Trending news",
			"SEARCH_TERM": {
				"LABEL": "Search term"
			},
			"STATUS": {
				"ALL": "All",
				"DELIVERED": "Delivered",
				"DRAFT": "Draft",
				"PARTIALLY_DELIVERED": "Partially delivered",
				"PENDING": "Pending"
			},
			"TYPE": {
				"BREAKING": "Breaking",
				"ROUTINE": "Routine"
			},
			"UPLOAD": {
				"HINT": "Allowed file extension: `.zip`"
			}
		},
		"VIDEO": {
			"ACTIONS": {
				"DETAILS": "Details"
			},
			"CREATE": {
				"ALERT": {
					"ERROR": "File upload failed. Details: {{value}}",
					"SUCCESS": "Video created"
				},
				"FIELD": {
					"CATEGORY": "Category",
					"COUNTRY": "Country",
					"VIDEO_PROVIDER": "Video provider"
				},
				"FORM": {
					"ACTIONS": {
						"CANCEL": "Cancel",
						"SUBMIT": "Submit"
					},
					"FIELDS": {
						"DESCRIPTION": {
							"LABEL": "Description",
							"PLACEHOLDER": "Provide a description"
						},
						"FILE": {
							"LABEL": "Upload your file"
						},
						"TITLE": {
							"LABEL": "Title",
							"PLACEHOLDER": "Provide a title"
						}
					}
				},
				"HEADER": "Create a video",
				"LABEL": "Create"
			},
			"DATEPICKER": {
				"LABEL": "Enter a created date"
			},
			"DETAILS": {
				"FIELD": {
					"CATEGORY": "Category",
					"COMPRESSED_URL": "Compressed URL",
					"COSMOSE_URL": "Cosmose URL",
					"CREATED_BY": "Created by",
					"KEYWORDS": "Keywords",
					"ORIENTATION": "Orientation",
					"ORIGINAL_URL": "Original URL",
					"PROVIDER_LANGUAGE": "Provider language",
					"PROVIDER_NAME": "Provider",
					"REVIEW_STATUS": "Review status",
					"STATUS": "Status",
					"TITLE": "Title",
					"VIDEO_ID": "Video ID"
				},
				"HEADER": "Video details"
			},
			"HEADER": "Videos",
			"REVIEW_STATUS": {
				"ACCEPTED": "Accepted",
				"DRAFT": "Draft",
				"REJECTED": "Rejected"
			},
			"SEARCH_TERM": {
				"LABEL": "Created by, Video name"
			},
			"STATUS": {
				"DONE": "Done",
				"ERROR": "Error",
				"FINISHING": "Finishing",
				"LABEL": "Status",
				"NEW": "New",
				"PROCESSING": "Processing",
				"TO_REFRESH": "To refresh"
			},
			"UPLOAD": {
				"HINT": "Allowed file extension: `.mp4`"
			}
		},
		"VIDEO_CAMPAIGN": {
			"ACTIONS": {
				"DETAILS": "Details"
			},
			"CREATE": {
				"HEADER": "Create video campaign"
			},
			"DETAILS": {
				"FIELD": {
					"CAMPAIGN_NAME": "Campaign Name",
					"END_DATE": "End Date",
					"START_DATE": "Start Date",
					"WEIGHT": "Weight"
				},
				"HEADER": "Video Campaign Details"
			},
			"HEADER": "Video Campaigns"
		}
	},
	"Password": "Password",
	"Password doesn't match": "Password doesn't match",
	"Password has been changed!": "Password has been changed!",
	"Password is required": "Password is required",
	"Password must contain at least one numeric character": "Password must contain at least one numeric character",
	"Password required": "Password required",
	"Passwords must be at least 8 characters in length": "Passwords must be at least 8 characters in length",
	"Promote article to Global Search News": "Promote article to Global Search News",
	"Promote article to Trending News": "Promote article to Trending News",
	"Promote articles to Global Search": "Promote articles to Global Search",
	"Promote articles to Trending News": "Promote articles to Trending News",
	"Provided temporary password is incorrect!": "Provided temporary password is incorrect!",
	"Publication time": "Publication Time",
	"Publish time": "Publish time",
	"Publisher": "Publisher",
	"REJECTED": "Rejected",
	"Repeat password": "Repeat Password",
	"Reset password": "Reset Password",
	"Resource type": "Resource Type",
	"Return": "Return",
	"Save": "Save",
	"Select image": "Select image",
	"Select main image": "Select main image",
	"Set as current": "Set as Current",
	"Size": "Size",
	"Start date": "Start date",
	"Status": "Status",
	"Subtitle": "Subtitle",
	"Summary": "Summary",
	"Tags": "Tags",
	"Temporary password": "Temporary Password",
	"Title": "Title",
	"Traffic percent": "Traffic percent",
	"Type": "Type",
	"Use as Lockscreen image": "Use as Lockscreen image",
	"Value has been updated": "Value has been updated",
	"Variant has been updated": "Variant has been updated",
	"Weekly frequency": "Weekly frequency",
	"Weight": "Weight"
}
