// :cow: Cosmose CONFIDENTIAL :iso:
import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: (): Promise<any> => import('./auth/auth.routes').then(m => m.routes),
      },
      {
        path: '',
        loadChildren: (): Promise<any> => import('./home/home.routes').then(m => m.routes),
      },
      {
        path: '',
        loadChildren: (): Promise<any> => import('./errors/errors.routes').then(m => m.routes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];
