// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@par/app/core/services/config.service';
import { ImageSuggestionVariantListPayload } from '../models/image-suggestion-variant-list.payload';
import { ImageSource, ImageSuggestionVariantListResponse } from '../models/image-suggestion-variant-list.response';
import { ImageSuggestionListVariant } from '@par/app/shared/image-suggestion-variant-list-dialog/models/image-suggestion-list.variants';

@Injectable({
  providedIn: 'root',
})
export class ImageSuggestionVariantListService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  getImageSuggestionVariantList(payload: ImageSuggestionVariantListPayload): Observable<ImageSuggestionVariantListResponse> {
    const url = `${ this.configService.getConfig().mindBrushApi }/v2/content_images_preview`;
    const variant = payload.variant === ImageSuggestionListVariant.LS ? 'LOCKSCREEN' : 'WEB_CONTENT';

    const params = new HttpParams()
      .set('kkn_content_id', payload.resourceId)
      .set('image_type', variant)
      .set('image_source', payload.imageSource)
      .set('image_orientation', 'ALL');

    return this.httpClient.get<ImageSuggestionVariantListResponse>(url, {params});
  }
}

